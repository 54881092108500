import React from 'react';
import './TypeformEmbed.css';

const TypeformEmbed = () => {
    const typeformURL = 'https://form.typeform.com/to/QlFyITst';
  
    return (
      <iframe
        title="Typeform"
        id="typeform-full"
        width="100%"
        height="100%"
        frameborder="0"
        src={typeformURL}
      ></iframe>
    );
  };
  

export default TypeformEmbed;
